<template>
    <div class="container">
        <the-login @authenticate="authenticate" v-if="!isAuthenticated"></the-login>

        <div v-else class="box">
            <transition name="slide" mode="out-in">
                <the-nps @realizouVotacao="realizouVotacao" @mudaTela="mudaTela" v-if="!isVoted"></the-nps>

                <div v-else>
                    <transition name="slide" mode="out-in">
                        <the-compliant @mudaTela="mudaTela" v-if="currentNota <= 6"></the-compliant>

                        <div class="thanks" v-else>
                            <p>Agradecemos pela sua colaboração!</p>
                        </div>
                    </transition>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import TheCompliant from './TheCompliant.vue';
import TheLogin from './TheLogin.vue';
import TheNps from './TheNps.vue';

export default {
    components: {
        TheCompliant,
        TheLogin,
        TheNps,
    },

    data() {
        return {
            isAuthenticated: false,
            isVoted: false,
            currentNota: null,
        };
    },

    created() {
        let login = JSON.parse(localStorage.getItem('login'));

        if (login) {
            const currentDate = new Date();
            const lastDate = new Date(login.logged);

            const differenceInTime = Math.abs(currentDate.getTime() - lastDate.getTime());
            const diffDays = differenceInTime / (1000 * 60 * 60 * 24);

            if (diffDays > 0.8) {
                localStorage.removeItem('login');
            } else {
                this.isAuthenticated = true;
            }
        }
    },

    methods: {
        mudaTela(value) {
            this.currentNota = value;

            if (value > 6) {
                setTimeout(() => {
                    this.isVoted = false;
                }, 1700);
            }
        },

        authenticate() {
            this.isAuthenticated = true;
        },

        realizouVotacao() {
            this.isVoted = true;
        },
    },
};
</script>

<style lang="scss">
.container {
    height: 100%;
    display: grid;
    place-content: center;
    padding: 20px;

    @media (min-width: 768px) {
        padding: 20px 50px;
    }

    @media (min-width: 992px) {
        padding: 20px 100px;
    }

    @media (min-width: 1200px) {
        padding: 20px 200px;
    }
}

.slide {
    &-leave-to {
        transform: translateY(-450px);

        @media (min-width: 768px) {
            transform: translateY(-300px);
        }

        @media (min-width: 1200px) {
            transform: translateY(-600px);
        }
    }

    &-enter-from {
        transform: translateY(450px);

        @media (min-width: 768px) {
            transform: translateY(300px);
        }

        @media (min-width: 1200px) {
            transform: translateY(600px);
        }
    }

    &-enter-to,
    &-leave-from {
        transform: translateY(0px);
    }

    &-enter-active,
    &-leave-active {
        transition: all 0.4s ease-in-out;
    }
}

.login {
    padding: 30px;
    width: 280px;
    border-radius: 10px;
    background-color: rgba($color: #fff, $alpha: 0.8);

    @media (min-width: 768px) {
        width: 450px;
        padding: 40px;
    }

    &__form {
        display: grid;
        gap: 15px;
    }

    &__label {
        font-weight: 700;
        font-size: 18px;
    }

    &__element {
        display: grid;
        gap: 5px;
    }

    &__input {
        padding: 10px;
        background-color: rgba($color: #fff, $alpha: 0.7);
        border: 1px solid #888;
        border-radius: 4px;

        &:focus {
            outline: none;
            border-color: #115711;
        }
    }

    &__btn {
        justify-self: center;
        cursor: pointer;
        width: 100px;
        padding: 10px;
        border-radius: 5px;
        background-color: #115711;
        color: #fff;
        border: 1px solid #115711;
    }

    &__error {
        color: red;
        font-size: 14px;
        font-weight: 900;
    }
}

.box {
    padding: 70px 30px;
    width: 300px;
    height: 450px;
    border-radius: 10px;
    background-color: rgba($color: #fff, $alpha: 0.7);
    overflow: hidden;

    @media (min-width: 768px) {
        height: 350px;
        width: 700px;
    }

    @media (min-width: 992px) {
        width: 850px;
    }

    @media (min-width: 1200px) {
        height: 600px;
        width: 1000px;
        padding: 80px;
    }
}

.nps {
    display: grid;
    gap: 50px;

    @media (min-width: 768px) {
        gap: 80px;
    }

    @media (min-width: 1200px) {
        gap: 150px;
    }

    &__text {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        height: fit-content;
        letter-spacing: 0.5px;

        @media (min-width: 768px) {
            font-size: 26px;
        }

        @media (min-width: 1200px) {
            font-size: 38px;
        }
    }

    &__btns {
        display: flex;
        flex-wrap: wrap;
        height: fit-content;
        position: relative;
        gap: 20px;

        @media (min-width: 768px) {
            justify-content: space-between;
            gap: 15px;
        }
    }

    &__btn {
        width: 45px;
        height: 45px;
        display: grid;
        place-content: center;
        border-radius: 7px;
        border: none;
        color: #010501;
        font-weight: 900;
        font-size: 18px;
        cursor: pointer;

        @media (min-width: 768px) {
            width: 42px;
            height: 42px;
        }

        @media (min-width: 992px) {
            width: 50px;
            height: 50px;
        }

        @media (min-width: 1200px) {
            width: 60px;
            height: 60px;
            font-size: 22px;
        }
    }

    &__suggestion {
        position: absolute;
        bottom: -24px;
        font-size: 16px;
        font-weight: 700;
        display: none;

        @media (min-width: 768px) {
            display: block;
        }

        @media (min-width: 1200px) {
            font-size: 28px;
            bottom: -38px;
        }

        &--right {
            right: 0;
        }

        &--left {
            left: 0;
        }
    }
}

.thanks {
    display: grid;
    place-content: center;
    height: 100%;
    gap: 15px;
    font-size: 18px;
    text-align: center;

    @media (min-width: 768px) {
        font-size: 45px;
    }

    @media (min-width: 1200px) {
        font-size: 60px;
    }
}

.complaints {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;

    &__btn {
        border: none;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0 0 5px #818181;
        letter-spacing: 0.5px;
        font-size: 18px;
        background-color: #f7f7f7;
        font-weight: 700;
    }
}
</style>