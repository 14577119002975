<template>
    <div class="nps">
        <div class="nps__text">
            Em uma escala de 0 a 10, qual a probabilidade de você recomendar a Hórus para seus conhecidos?
        </div>

        <div class="nps__btns">
            <template v-for="(n, index) in buttons" :key="index">
                <button
                    type="button"
                    class="nps__btn"
                    :style="'background-color:' + n.color"
                    @click="changeVote(index)"
                >
                    {{ index }}
                </button>
            </template>

            <small class="nps__suggestion nps__suggestion--left">Nada Provável</small>
            <small class="nps__suggestion nps__suggestion--right">Muito Provável</small>
        </div>
    </div>
</template>

<script>
import HTTP from '../http/http.js';

export default {
    data() {
        return {
            buttons: [
                {
                    color: '#F64910',
                },
                {
                    color: '#FB450A',
                },
                {
                    color: '#FE6706',
                },
                {
                    color: '#FD6502',
                },
                {
                    color: '#FF8E01',
                },
                {
                    color: '#FDC629',
                },
                {
                    color: '#EBDB0B',
                },
                {
                    color: '#7fe225',
                },
                {
                    color: '#0BDD40',
                },
                {
                    color: '#00E600',
                },
                {
                    color: '#0DD00D',
                },
            ],
        };
    },

    computed: {
        getCurrentDate() {
            let date = new Date();

            let month = ('0' + (date.getMonth() + 1)).slice(-2);
            let day = ('0' + date.getDate()).slice(-2);
            let year = date.getFullYear();
            let hour = ('0' + date.getHours()).slice(-2);
            let min = ('0' + date.getMinutes()).slice(-2);
            let seg = ('0' + date.getSeconds()).slice(-2);
            return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + seg;
        },
    },

    methods: {
        changeVote(nota) {
            let usuario = JSON.parse(localStorage.getItem('login'));
            let usuario_id = usuario.usuario_id;
            let created_at = this.getCurrentDate;

            let npsData = {
                nota,
                usuario_id,
                created_at
            };

            this.$emit('mudaTela', nota);

            let notasStorage = JSON.parse(localStorage.getItem('notas'));
            let complaintsStorage = JSON.parse(localStorage.getItem('complaints'));

            if (navigator.onLine) {
                HTTP.post('nps', npsData);

                if (notasStorage) {
                    notasStorage.forEach((data) => {
                        HTTP.post('nps', data);
                    });

                    localStorage.removeItem('notas');
                }

                if (complaintsStorage) {
                    complaintsStorage.forEach((data) => {
                        HTTP.post('complaint', data);
                    });

                    localStorage.removeItem('complaints');
                }
            } else {
                let dataToLS = null;

                if (notasStorage) {
                    notasStorage.push(npsData);

                    dataToLS = JSON.stringify(notasStorage);
                } else {
                    dataToLS = JSON.stringify([npsData]);
                }

                localStorage.setItem('notas', dataToLS);
            }

            this.$emit('realizouVotacao');
        },
    },
};
</script>