<template>
    <the-body></the-body>
</template>

<script>
import TheBody from './components/TheBody.vue';

export default {
    name: 'App',
    components: {
        TheBody,
    },
};
</script>

<style lang="scss">
#app {
    font-family: 'Source Sans Pro', sans-serif;
    background-image: url('./assets/images/horus.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    width: 100%;
    height: 100vh;
}
</style>
