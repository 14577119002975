<template>
    <div class="login">
        <form class="login__form" method="POST" @submit.prevent="logar" autocomplete="off">
            <div class="login__element">
                <label class="login__label" for="nome">Usuário</label>
                <input
                    class="login__input"
                    id="nome"
                    required
                    type="text"
                    autocomplete="off"
                    autofocus
                    v-model="nome"
                />
            </div>

            <div class="login__element">
                <label class="login__label" for="senha">Senha</label>
                <input class="login__input" id="senha" required type="password" v-model="senha" />
            </div>

            <div class="login__error" v-if="usuarioInvalido">Usuário ou senha inválidos!</div>

            <button type="submit" class="login__btn">Entrar</button>
        </form>
    </div>
</template>

<script>
import HTTP from '../http/http.js';

export default {
    data() {
        return {
            senha: '',
            nome: '',
            usuarioInvalido: false,
        };
    },
    methods: {
        logar() {
            let usuarioData = {
                nome: this.nome,
                senha: this.senha,
            };

            HTTP.post('login', usuarioData)
                .then((res) => {
                    let usuario = res.data;

                    let data = {
                        usuario_id: usuario.id,
                        username: usuario.nome,
                        email: usuario.email,
                        role: usuario.role_id,
                        logged: this.getCurrentDate,
                    };
                    localStorage.setItem('login', JSON.stringify(data));
                    this.$emit('authenticate');
                })
                .catch(() => {
                    this.usuarioInvalido = true;
                });
        },
    },
};
</script>

<style>
</style>