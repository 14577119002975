<template>
    <div class="nps">
        <div class="nps__text">O que poderia ter sido melhor?</div>

        <div class="complaints">
            <button type="button" class="complaints__btn" @click="saveComplaint(1)">Comida</button>
            <button type="button" class="complaints__btn" @click="saveComplaint(2)">Atendimento Garçom</button>
            <button type="button" class="complaints__btn" @click="saveComplaint(3)">Atendimento Recepção</button>
            <button type="button" class="complaints__btn" @click="saveComplaint(4)">Ambiente</button>
            <button type="button" class="complaints__btn" @click="saveComplaint(5)">Cerveja</button>
            <button type="button" class="complaints__btn" @click="saveComplaint(6)">Tempo de Espera</button>
        </div>
    </div>
</template>

<script>
import HTTP from '../http/http.js';

export default {
    data() {
        return {
            timeLeft: null,
        };
    },
    mounted() {
        this.timeLeft = setTimeout(() => {
            this.$emit('mudaTela', 100);
        }, 7000);
    },

    beforeUnmount() {
        clearTimeout(this.timeLeft);
    },

    computed: {
        getCurrentDate() {
            let date = new Date();

            let month = ('0' + (date.getMonth() + 1)).slice(-2);
            let day = ('0' + date.getDate()).slice(-2);
            let year = date.getFullYear();
            let hour = ('0' + date.getHours()).slice(-2);
            let min = ('0' + date.getMinutes()).slice(-2);
            let seg = ('0' + date.getSeconds()).slice(-2);
            return year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + seg;
        },
    },

    methods: {
        saveComplaint(complaint_id) {
            let created_at = this.getCurrentDate;

            let complaintData = {
                complaint_id,
                created_at
            };

            let complaintsStorage = JSON.parse(localStorage.getItem('complaints'));

            if (navigator.onLine) {
                HTTP.post('complaint', complaintData);
            } else {
                let dataToLS = null;

                if (complaintsStorage) {
                    complaintsStorage.push(complaintData);

                    dataToLS = JSON.stringify(complaintsStorage);
                } else {
                    dataToLS = JSON.stringify([complaintData]);
                }

                localStorage.setItem('complaints', dataToLS);
            }

            this.$emit('mudaTela', 100);
        },
    },
};
</script>

<style>
</style>